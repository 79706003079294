<template>
  <div class="max-w-7xl mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8">
    <h2 class="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
      Clear and Simple Pricing
    </h2>
    <p class="my-6 max-w-2xl text-xl text-gray-500">Our plans scale with your needs. Upgrade or downgrade at any time.</p>

    <div class="mt-12 grid justify-items-center">
      <SwitchGroup as="div" class="flex items-center">
        <Switch v-model="yearlyEnabled" :class="[yearlyEnabled ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
          <span aria-hidden="true" :class="[yearlyEnabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
        </Switch>
        <SwitchLabel as="span" class="ml-3">
          <span class="text-sm font-medium text-gray-900">Annual billing </span>
          <span class="text-sm text-gray-500">(Save 33%)</span>
        </SwitchLabel>
      </SwitchGroup>
    </div>

    <!-- Tiers -->
    <div class="mt-12 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div v-for="tier in currentPlans" :key="tier.title" class="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col">
        <div class="flex-1">
          <h3 class="text-xl font-semibold text-gray-900">{{ tier.title }}</h3>
          <p v-if="tier.mostPopular" class="absolute top-0 py-1.5 px-4 bg-indigo-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">Most popular</p>
          <p class="mt-4 flex items-baseline text-gray-900">
            <span v-if="tier.frequency === 'year'" class="text-5xl font-extrabold tracking-tight">${{ tier.price / 12 }}</span>
            <span v-else class="text-5xl font-extrabold tracking-tight">${{ tier.price }}</span>
            <span v-if="tier.price === 0" class="ml-1 text-xl font-semibold">/ forever</span>
            <span v-else class="ml-1 text-xl font-semibold">/ month</span>
          </p>
          <p class="mt-6 text-gray-500">{{ tier.description }}</p>

          <!-- Feature list -->
          <ul role="list" class="mt-6 space-y-6">
            <li v-for="feature in tier.features" :key="feature" class="flex">
              <CheckIcon class="flex-shrink-0 w-6 h-6 text-indigo-500" aria-hidden="true" />
              <span class="ml-3 text-gray-500">{{ feature }}</span>
            </li>
          </ul>
        </div>

        <a href="https://app.blockbase.dev/" :class="[tier.mostPopular ? 'bg-indigo-500 text-white hover:bg-indigo-600' : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-100', 'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium']">{{ tier.cta }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/outline'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'

const pricing = [
  {
    title: 'Free',
    price: 0,
    frequency: ' forever',
    description: 'Perfect for capturing invoices, newsletters, and more.',
    features: [
      '1 address->Notion DB link',
      'Up to 90 emails/month',
      'Email support'
    ],
    cta: 'Get Started',
    mostPopular: false,
  },
  {
    title: 'Pro',
    price: 3,
    frequency: 'month',
    description: 'Handle client emails, CI/CD failures, or build your own ticketing system.',
    features: [
      '3 address->Notion DB links',
      'Up to 9000 emails/month',
      'Email and Slack support',
    ],
    cta: 'Start For Free',
    mostPopular: true,
  },
  {
    title: 'Team',
    price: 30,
    frequency: 'month',
    description: 'Dedicated support and custom tooling for your team.',
    features: [
      '20 address->Notion DB links',
      'Up to 90k emails/month',
      'Zoom, phone, Slack, email support'
    ],
    cta: 'Start For Free',
    mostPopular: false,
  },
  {
    title: 'Pro Annual',
    price: 24,
    frequency: 'year',
    description: 'Handle client emails, CI/CD failures, or build your own ticketing system.',
    features: [
      '3 address->Notion DB links',
      'Up to 9000 emails/month',
      'Email and Slack support',
    ],
    cta: 'Start For Free',
    mostPopular: true,
  },
  {
    title: 'Team Annual',
    price: 240,
    frequency: 'year',
    description: 'Dedicated support and custom tooling for your team.',
    features: [
      '20 address->Notion DB links',
      'Up to 90k emails/month',
      'Zoom, phone, Slack, email support'
    ],
    cta: 'Start For Free',
    mostPopular: false,
  },
]

export default {
  components: {
    CheckIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  data() {
    return {
      yearlyEnabled: true,
    }
  },
  computed: {
    currentPlans() {
      var curPlans = []
      var currentFrequency = 'month'
      if (this.yearlyEnabled) {
        currentFrequency = 'year'
      }
      for (var planidx in pricing) {
        if (pricing[planidx].title === 'Free' || pricing[planidx].frequency === currentFrequency) {
          curPlans.push(pricing[planidx])
        }
      }
      return curPlans
    }
  },
}
</script>
